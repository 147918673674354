<template>
    <div class="router-transitions billing-layout">
        <tactful-page-header>
            <span slot="iconTitle">
                <div class="d-flex align-items-center">
                    <span @click="goToSM" class="parent-route">{{ $t('billing.account') }}</span>
                    <i v-if="in_plans" class="far fa-chevron-right arrow-icon mx-2 position-relative"></i>
                    <span v-if="in_plans" class="child-route">{{ $t('billing.childRoute') }}</span>
                </div>
            </span>
        </tactful-page-header>
        <router-view></router-view>
    </div>
</template>

<script>
import TactfulPageHeader from "@tactful/tactful-shared/components/TactfulPageHeader";

export default {
    name: 'BillingLayout',
    components: { TactfulPageHeader },
    computed: {
        in_plans() {
            return (/plans/gm).test(this.$route.path);
        }
    },
    methods: {
        goToSM() {
            this.$router.push({path: '/subscription-management'})
        }
    }
}
</script>

<style lang="scss" scoped>
.billing-layout {
    font-family: $font-family-sans-serif-base;
    font-style: normal;
    font-weight: bold;
    padding: 30px;
    background-color: #F5F5F5;
    min-height: 100vh;
}

.parent-route {
    color: $blue-900;
    cursor: pointer;
}

.child-route {
    color: $secondary-text;
}
</style>