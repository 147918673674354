export default {
  "billing":{
     "account":"Subscription Management",
     "plan":"Plan",
     "status":"Status",
     "cost":"Unit Cost",
     "qunatity":"Qunatity",
     "managePlan":"Manage Plan",
     "usage":"USAGE",
     "startdate":"Start date",
     "enddate":"End date",
     "agentSeats":"Total Agents Seats",
     "monthlycustomers":"Monthly Customers",
     "monthlycampaigns":"Monthly Campaigns",
     "utilized":"Utilized",
     "unlimited":"Unlimited",
     "customers":"Customers",
     "seats":"Seats",
     "campaigns":"Campaigns",
     "billingHistory":"Billing History",
     "transaction":"Transaction ID",
     "childRoute":"All Plans",
     "in_trial":"Trial",
     "active":"Active",
     "cancelled":"Cancelled",
     "noBillingState":"Status Unavailable",
     "choosePlan":"Choose the billing plan that fits you.",
     "billMonthly":"Billed Monthly",
     "billAnnual":"Billed Annually",
     "viewAllBtn":"View All",
     "currentPlan":{
        "currentPlanTitle":"Current Plan",
        "billed":{
            "month":"Billed monthly",
            "year":"Billed annually",
            "once":"Billed once",
            "addons": "Addons"
         },
        "renewsAt":"Renews at",
        "endsAt":"ends on",
        "per":"Per",
        "upgradeBtn":"Change plan",
        "checkoutBtn": "Checkout",
        "subscribeBtn": "Subscribe",
        "cancelBtn": "Cancel",
        "manageBillingBtn": "Manage Billing",
        "upgradeBtnInTrial":"Subscribe to a plan",
        "planOnTrial":"Free trial",
        "trialEndNote":"Your {trial_period}-{trial_period_unit} trial period"
     },
     "subscribeBtn":"Subscribe",
     "usageDetails":{
        "title":"Usage Details",
        "outOf":"Out of",
        "total_users_seats":"Users",
        "total_channels":"Active channels",
        "total_image_size_megabyte":"Used storage"
     },
     "paymentMethod":{
        "title":"Payment Method",
        "default":"Default",
        "cardEnding":"Card ending in",
        "expiryDate":"Expiry date",
        "nameOnCard":"Name on the card"
     },
     "plans":{
        "currentTag":"Current",
        "seatsNo":"Seats No.",
        "totalAmount":"Total Amount",
        "USD":"$",
        "EUR":"Є",
        "GBP":"£",
        "NLNote":"Our team will be happy to help you with selecting the best plan for your business \n\n and any other subscription questions by phone",
        "month":"Seat/Month",
        "year":"Seat/Year"
     },
     "paymentToast":{
        "firstPart":"You've subscribed to plan",
        "secondPart":"successfully",
        "popupNote": "We’re finalizing your request…"
     },
     "paymentPopup":{
        "title":"Successful Payment",
        "firstPart":"Thank you for subscribing to our bundle! Your decision has made us even more excited about providing you with an exceptional experience.",
        "secondPart":"Our team is dedicated to ensuring that you make the most of your subscription. Should you have any questions, please do not hesitate to reach out to us from",
        "thirdPart":"We're here to assist you every step of the way.",
        "here":"here"
     },
     "home":{
        "endsAt":"Ends on"
     },
     "invoices": {
         "title": "Invoices",
         "id": "Invoice",
         "fields": {
            "id": "Invoice ID",
            "status": "Status",
            "date": "Issue Date",
            "total": "Amount",
            "plan": "Plan",
         },
         "payNowBtn": 'Pay Now',
         "noDueInvoices": "No due invoices"
     },
     "cancelSubscriptionPopup": {
      "title": "Cancel Subscription",
      "body": "You are about to cancel your subscription, your access to all premium features will stop immediately.",
      "question": "Are you sure you want to cancel it?",
      "cancelBtn": "keep Plan",
      "confirmBtn": "Cancel Subscription",
      "successMsgTitle": "Subscription Canceled",
      "successMsgBody": "Your subscription has been successfully cancelled"
     }
  },
  "trialEnded": {
      "title": "Your trial has been expired",
      "desc": "All the data and settings are saved, subscribe to one of the plans to activate your workspace.",
      "descForReseller": "Your subscription is suspended, Please contact your account manager.",
      "btn": "Subscribe now",
      "contactBtn": "Contact Now"
  },
  "billingPeriodUnit":{
     "month":"Billed monthly",
     "year":"Billed annually"
  },
  "businessInfo":{
     "pageHeader":"Business Info",
     "buttons":{
        "save":"Save",
        "discard":"Discard"
     },
     "saveSuccess":"Changes saved successfully!",
     "generalInfo":{
        "title":"General Info",
        "subTitle":"Workspace ID",
        "labels":{
           "logo":"Business Logo",
           "name":"Business Name",
           "link":"Website Link",
           "timezone":"Timezone",
           "hours":"Work Availability",
           "hoursTooltip":"Please click to choose your working days & Time",
           "days":"Working Days",
           "timing":"Business Timing",
           "from":"From",
           "to":"To"
        },
        "days": {
            "monday": "monday",
            "tuesday": "tuesday",
            "wednesday": "wednesday",
            "thursday": "thursday",
            "friday": "friday",
            "saturday": "saturday",
            "sunday": "sunday"
        }
     },
     "contactInfo":{
        "title":"Contact Info",
        "labels":{
           "email":"Email",
           "phone":"Phone Number",
           "country":"Country",
           "address":"Address"
        }
     },
     "socialMedia":{
        "title":"Social Media Links",
        "labels":{
           "insta":"Instagram Link",
           "facebook":"Facebook Link",
           "twitter":"X-Twitter Link",
           "whatsapp":"Whatsapp Number"
        }
     }
  },
  "channelsList":{
     "channels":"Channels",
     "connectNew":"Connect New Channel",
     "search":"Search by channel, brand or tag name.",
     "noChannelsTitle":"You don't have any connected channels yet!",
     "noChannelsDesc":"You need to connect at least one channel like Website LiveChat, Messenger, or WhatsApp in order to allow your team to talk with your audience or build a bot.",
     "upgradeNow":"upgrade Now",
     "connectWhatsappTitle":"Connect Whatsapp Channel",
     "connectWhatsappText":"In order to create a whatsapp channel ,Kindly contact our support team or visit the link below and fill out the request form ",
     "fields": {
         "type": "Type",
         "status":"Status",
         "configuration": "Configuration"
      },
     "edit":"Edit",
     "channelsError":"Error message for channels list page for server errors",
     "crashed":"Crashed",
     "deactivatedTooltip":"Channel is Inactive.",
     "disconnectedTooltip":"Unfortunately, there is a connection error."
  },
  "discardChangesModal":{
     "title":"Unsaved Changes",
     "cancel":"Stay",
     "discard":"Leave Page",
     "confirm":"Confirm",
     "message":"You have unsaved changes, Are you sure you want to leave this page?",
     "messageWebChat":"This will discard all changes made",
     "leaveProccessMessage":"Uploads are still in progress,it may be lost if you leave this page",
     "no":"Continue Editing",
     "yes":"Discard Changes"
  },
  "homepagePopup":{
     "title":"Congrats... You are ready to go live!",
     "btn1":"Try as Customer",
     "btn2":"Try as Agent",
     "btn3":"Invite Team"
  },
  "homepage":{
     "setupCompletion":"Your Setup Completion",
     "statistics":"Last 24 hours",
     "statisticsReports":"View All Reports",
     "viewAllBtn":"View All Reports",
     "statisticsRestricted": "No data to show",
     "statisticsLabels": {
        "liveConvo": "Live Conversations",
        "weekConvo": "Conversations This Week",
        "waitTime": "Avg. Waiting Time",
        "handlingTime": "Avg. Handling Time",
     },
     "learnMore": {
         "title": "Learn More",
         "desc": "Here you will find the links that will help you learn more about our solution.",
         "btn":"Support Center"
      },
      "channels": {
         "title": "Channels",
         "pending":"Pending",
         "setup": "Setup",
         "install": "Install"
      },
      "teamMembers": {
         "title": "Team Members",
         "noTeamMembers":"There are no team members ... ",
         "btn": "Manage Team"
      },
      "currentPlan": {
         "title": "Current Plan",
         "planPrice":"Price",
         "planName":"Plan",
         "upgradeBtn":"Upgrade",
         "detailsBtn": "Billing Details"
      },
      "advancedSetup": {
         "title": "Advanced Setup",
         "faqBtn": "FAQs",
         "queuesBtn": "Setup Group",
      }
  },
  "imagePicker":{
     "title":"Image Picker",
     "chooseImage":"Update Image",
     "closeBtn":"Close",
     "upload":"Upload",
     "noImages":"You don't have any images yet. Press Upload to add some images",
     "search":"Search by name",
     "noResult":"You haven’t used this image before.",
     "sorryMsg":"Want to upload it from your device?",
     "copyURL":"Copy Image link",
     "uploadOrDrag":"Drag & drop your images here or click to upload",
     "Previousupload":"Previous Uploads",
     "bactToPreviousupload":"Back To Previous Uploads",
     "uploadFromDevice":"Upload From Device / URL",
     "fromUrl":"From URL",
     "recentUploads":"Recent Uploads",
     "save":"Save",
     "select":"Select",
     "cancel":"Cancel"
  },
  "imageViewer":{
     "support":"Supports PNG & JPG",
     "uploadImage":"Upload Image",
     "facebookLogoHint":"Facebook page picture will appear for your customers.",
     "whatsappLogoHint":"WhatsApp profile picture will appear for your customers.",
     "recommendedSize": "Recommended size 2MB or less",
  },
  "meta":{
     "homepage":"Home",
     "billing":"Subscription",
     "trialEnded":"Trial Ended",
     "pricing":"Plans",
     "businessInfo":"Business Info"
  },
  "notify":{
     "connectChannelSuccessTitle":"Channel Connecting",
     "connectChannelSuccessText":" is Successfully connected.",
     "editChannelSuccessTitle":"Channel Editing",
     "editChannelSuccessText":" is Successfully edited.",
     "deleteChannelSuccessTitle":"Channel Removing",
     "deleteChannelSuccessText":" is Successfully removed.",
     "success":"SUCCESSS",
     "error":"ERROR",
     "invalidURL":"Invalid URL",
     "errorFetch":"Loading information failed",
     "successLoad":"Loading information succeeded",
     "passwordSuccess":"New password created successfully",
     "emailVerification":"Email verified successfully",
     "invalidType":"Invalid type entered",
     "NumericValidationError":"Not accepting numeric values",
     "errorDelete":"Deleting information failed",
     "successDelete":"Deleting information succeeded",
     "errorSave":"Saving information failed",
     "successSave":"Saving information succeeded",
     "errorSaveProfile":"This profile name already exists",
     "ConnectFBLoginFailed":"Login with Facebook failed",
     "LoadFBPagesFailed":"Failed loading Facebook pages",
     "FBPagesEmpty":"Could not find any Facebook pages",
     "FBAutherization":"Facebook login has not been authorized",
     "fbPageConnected":"Page has been connected successfully",
     "fbPageDisconnected":"Page has been disconnected successfully",
     "pageActionFailed":"Page connection failed, please try again or select another page.",
     "currentProfileFailed":"Profile does not exist",
     "checkValidationError":"Please check entered information",
     "networkConnectionFailed":"Please check your internet connection!",
     "ServerError":"Server error ",
     "ClientError":"Bad request",
     "buttonsExist":"\"Card\", \"Carousel\" and \"Text Attached\" message must contain at least one button",
     "errorTrain":"Failed to train",
     "successArabicTrain":"Trained Arabic model Successfully",
     "successEnglishTrain":"Trained English model Successfully",
     "successUpgrade":"Upgrade Data Successfully",
     "errorUpgrade":"Failed to upgrade data",
     "successReset":"Reseting default settings succeeded",
     "errorReset":"Reseting default settings failed",
     "errInSendMsg":"Please connect your profile with Facebook page",
     "invalidTime":"Invalid Time",
     "missingData":"Please fill the missing Data",
     "quickReply":"You must add at least one message from other types",
     "errInLanguage":"You can't add arabic entity",
     "enterRoomNumberAndChannel":"Please enter room number and choose channel",
     "numberOfWhatsapp":"Please enter WhatsApp number",
     "saveSample":"Saving examples failed",
     "saveIntent":"Saving intent failed",
     "modelsTraining":"All models trained successfully",
     "modelsFailure":"Training Failed",
     "errorRemove":"Error in removing this entity",
     "errorRemoveModel":"Error in removing this model",
     "successChangeIntent":"Intent Changed Successfully",
     "errorChangeIntent":"Error in changing Intent",
     "addModelSuccess":"Model Created Successfully",
     "removeModelSuccess":"Model Removed Successfully",
     "saveSampleSuccess":"Example(s) saved successfully",
     "changeStateSuccess":"State Changed Successfully",
     "changeStateFailure":"Error in Changing State",
     "invitaionSentSuccess":"Invitation Sent Successfully",
     "userDeletedSuccssfully":"User Deleted Successfully",
     "errorDeleteUser":"Error in deleting user",
     "roleChanged":"Role Changed Successfully",
     "errorInChangingRole":"Error in changing role",
     "invitationDeleted":"Invitation Deleted Successfully",
     "errorDeleteInvitation":"Error in deleting inviation",
     "invitationEmailResent":"Invitation Email Resent Successfully",
     "errorInvitationEmailResent":"Error in resending invitation email.",
     "errorIndivert":"Error in Divert Call, You must add number of the agent or the service group",
     "topics":"New topics added successfully",
     "topicsFailed":"Failed to add new topics",
     "sendEmailFailed":"Can't Send Email Reply",
     "dateRange":"Invalid date range",
     "deactivationTitle":"Channel Deactivation",
     "deactivationText":"is successfully deactivated.",
     "activationTitle":"Channel Activation",
     "activationText":"is successfully activated.",
     "paymentSuccess":"Subscriped to a new plan successfully!",
  },
  "progressBar":{
     "businessInfo":"Business Info",
     "setupLanguages":"Setup Languages",
     "inviteTeam":"Invite Team",
     "personalProfile":"Personal Profile"
  },
  "store":{
     "product":"Items",
     "category":"Categories",
     "catSearch":"Item Name",
     "productSearch":"Item Search...",
     "importCategories":"Import Categories",
     "exportCategories":"Export Categories",
     "importProducts":"Import Items",
     "exportProducts":"Export Items",
     "addProduct":"Add New Item",
     "addCategory":"Add New Category",
     "editCategory":"Category",
     "name":"Name",
     "sku":"Sku",
     "descriptionTitle":"Description",
     "description":"Briefly describe this category",
     "parentCategory":"Parent Category",
     "moreChoices":"Additional Settings",
     "available":"Available",
     "collect":"Collect",
     "delivery":"Delivery",
     "offer":"Offer",
     "hide":"Hide",
     "images":"Images",
     "cancel":"Cancel",
     "save":"Save changes",
     "editProduct":"Product",
     "tax":"Tax",
     "discount":"Discount",
     "type":"Product options",
     "productType":"Single item",
     "addition":"Addition",
     "meal":"Bundle",
     "variations":"Variations",
     "price":"Price",
     "remove":"Remove",
     "add":"Add",
     "fullDescription":"Full description",
     "import":"Import",
     "export":"Export",
     "edit":"Edit",
     "link":"Copy link",
     "copy":"Copy",
     "warning":"If you delete this record, it will be gone forever.",
     "delete":"Delete",
     "copySuccess":"Link Copied",
     "copyFailed":"Failed to copy link",
     "groups":"Language",
     "exportComp":"Export now",
     "useDefualtButtons":"Use default buttons for product card",
     "importMessages":"Import Messages",
     "exportMessages":"Export Messages",
     "addMessage":"Add Message",
     "addPerantCategory":"Add parent catrgory",
     "title":"Title",
     "channels":"Use in Channels",
     "selectChannel":"Select Channel",
     "channelTags":"Select Channel"
  },
  "veeValidateMsgs":{
     "required":"This field is required",
     "invalidEmail":"Invalid email address",
     "invalidPhone":"This field must be digits only",
     "embtyCaret":"Add at least one product",
     "minNumber":"Minimum number is {min}",
     "phoneNumber":"Invalid phone number",
     "noSpaces":"Spaces not allowed",
     "wrongUrl":"Please add a valid url",
     "wrongVariable":"Wrong form, should be like: name=123 or example-name=123",
     "positiveNumbers":"Negative numbers aren't allowed",
     "closeTime":"Close time should be greater than open time"
  },
  "notFound": {
      "msg": "We‘re sorry, The page you were looking for couldn't be found."
   },
   "unauthorized": {
      "msg": "We‘re sorry, You don't have permission to access this page"
   }
}