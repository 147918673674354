import { ChannelSDK , ChannelConfiguration } from "@tactful/channels";
import { config } from '@/config';
import { Configuration as liveChatConfiguration , initializeLiveCommands } from '@tactful/livechat-client';
import { Configuration as tenantsConfig, initializeTactful as tenantsInit } from "@tactful/tenant-sdk";
import { Configuration as authConfigurations, initializeTactful as initializeAuth } from '@tactful/auth';
import { Configuration as AttachmentConfig, initializeTactful as AttachmentInit } from "@tactful/attachments-sdk-typescript"
import { Configuration as ErpConfig, initializeTactful as ErpInit } from "@tactful/erp-sdk-typescript";
import { getToken } from '@tactfulEngage/utilities'
export let token = getToken();

export const channelsApiConfig = new ChannelConfiguration({ 
  basePathMeta: config.CHANNELS_API_HOST_URL,
  basePathEmail: config.EMAIL_API_HOST_URL,
  basePathWebChat: config.CHANNELS_WEBCHAT_API_HOST_URL,
  apiKey: `Bearer ${token}`,
  options:{
    mode: 'cors', 
  }
});
export const channelsCore = new ChannelSDK(channelsApiConfig);

export const authConfig = new authConfigurations({
  basePath: config.AUTH_API_HOST_URL,
  apiKey: `Bearer ${token}`,
  options: {
    mode: 'cors',
  }
});
export const authCore = initializeAuth(authConfig);

// image upload 
export const attachmentApiConfig = new AttachmentConfig({
  basePath: config.ATTACHMENTS_API_HOST_URL,
  apiKey: `Bearer ${token}`,
  options: {
    mode: 'cors',
  }
});
export const erpApiConfig = new ErpConfig({
  basePath: config.ERP_API_HOST_URL,
  apiKey: `Bearer ${token}`,
  options: {
    mode: 'cors',
  }
});

export const AttachmentCore = AttachmentInit(attachmentApiConfig);
export const ErpCore = ErpInit(erpApiConfig);
const livechatServerConfig = new liveChatConfiguration({ basePath: config.EVENTS_HOST_URL, apiKey:`Bearer ${token}`, options: { mode: 'cors', } });
export const commands = initializeLiveCommands(livechatServerConfig).commands;

export function setApiKey() {
  token = getToken();

  livechatServerConfig.apiKey = `Bearer ${token}`;

  apiTenantsConfig.apiKey = `Bearer ${token}`;
  tenantsInit(apiTenantsConfig)

  channelsApiConfig.apiKey = `Bearer ${token}`;
  new ChannelSDK(channelsApiConfig);

  authConfig.apiKey = `Bearer ${token}`;
  initializeAuth(authConfig);
  
  attachmentApiConfig.apiKey = `Bearer ${token}`;
  AttachmentInit(attachmentApiConfig);
  
  erpApiConfig.apiKey = `Bearer ${token}`;
  ErpInit(erpApiConfig);
}


const apiTenantsConfig = new tenantsConfig({
  basePath: config.TENANT_API_HOST_URL,
  apiKey: `Bearer ${token}`,
  options: {
  mode: 'cors',
  }
  });
  export const TenantsCore = tenantsInit(apiTenantsConfig);


