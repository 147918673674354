<template>
    <div class="router-transitions">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'BlankLayout'
}
</script>

<style lang="scss" scoped>

</style>