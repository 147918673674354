import Vue from 'vue'
export interface ProfileState {
    loading: boolean;
}

export const profile = {
    state: Vue.observable<ProfileState>({
        loading: false,
    }),
    setLoading(loading) {
        this.state.loading = loading;
    }
};