import vue from 'vue'
import { TenantsCore } from "@/services";
import { getProfile, getCurrentProfileId } from '@tactfulEngage/utilities'

export interface Tenants {
    profile_id: Number,
    getLaunchpadProgress: [],
    myBillingInfo: [],
    getLimits:[],
    getProgressPercentage: Number,
    getCurrentStep: Number,
    getSteps: [],
    profile_website: String,
    profile_usage_type: String,
    referrer_info: Object,
    profileData: any,
}

export const Tenants = {
    state: vue.observable<Tenants>({
        profile_id: null,
        getLaunchpadProgress: [],
        myBillingInfo: [],
        getLimits: [],
        getProgressPercentage: null,
        getCurrentStep:0,
        getSteps: [],
        profile_website: null,
        profile_usage_type: null,
        referrer_info: {},
        profileData: {},
    }),
    async getProfile(profile) {
        const res = await TenantsCore.profile.getProfile({profile:profile});
        this.fillBusinessInfoData(res);
        return res;
    },
    fillBusinessInfoData(data) {
        this.state.profile_website = data.website_link;
        this.state.profile_usage_type = data.usage_type;
        this.state.referrer_info = data.referrer_info;
        this.state.profileData = data;
    },
    async putProfile(profile, payload) {
        const res = await TenantsCore.profile.putProfile({profile, payload})
        this.fillBusinessInfoData(res);
        return res;
    },
}