import Vue from 'vue'
import { user } from './user';
import { config } from '@/config';
import { TenantsCore } from "@/services";
import { getCurrentProfileId, getProfileRole, getCurrentUserId } from '@tactfulEngage/utilities';
import { Card, BillingPlan, BillingCustomer, Invoice, ConsumptionSummary, Limits, Reseller } from '@tactful/tenant-sdk';

const userId = getCurrentUserId();

interface BillingState {
    customer: BillingCustomer;
    subscription: Object
    card: Card,
    plan: BillingPlan,
    invoices: Array<Invoice>,
    plans : Array<BillingPlan>,
    hostedPage: any,
    usage: Array<ConsumptionSummary>,
    nextBillingDate: Date,
    provider: string,
    limits: Limits,
    in_trial: Boolean,
    trial_ends_on_date: String,
    subscription_status: String,
    subscription_cost: String,
    currency_code: String,
    subscription_renewal_at: String,
    current_plan: Object,
    monthly_plans: [],
    annual_plans: [],
    selected_plan: Object,
    myBillingLimits: [],
    resellerData: Reseller
}

export const BillingStore = {
    state: Vue.observable<BillingState>({
        customer: {},
        subscription: {},
        card:{},
        plan: {},
        invoices:[],
        plans :[],
        hostedPage:{},
        usage: [],
        nextBillingDate: null,
        provider: null,
        limits: {},
        in_trial: false,
        trial_ends_on_date: null,
        subscription_status: null,
        subscription_cost: null,
        currency_code: null,
        subscription_renewal_at: null,
        current_plan: null,
        monthly_plans: [],
        annual_plans: [],
        selected_plan: {},
        myBillingLimits: [],
        resellerData: {}
    }),

    async initializeChargebee() {
        this.chargebeeClient = window.Chargebee.init({
            site: config.CHARGEBEE_SITE, // your test site
            publishableKey: config.CHARGEBEE_PUBLISHABLE_API_KEY
        })
    },

    async getBillingInfo(profile_id: number = getCurrentProfileId()) {
        let res = await TenantsCore.billing.getBillingInfo({profile: profile_id});
        if (!res) {
            throw "No Billing Info";
        }
        if (res.customer) {
            this.state.customer = res.customer;
        }
        if (res.subscription) {
            this.state.subscription = res.subscription;
        }
        if (res.plan) {
            this.state.plan = res.plan;
        }
        if(res.invoices){
            this.state.invoices.splice(0, this.state.invoices.length);
            this.state.invoices.push(...res.invoices);
        }      
        if(res.card){
            this.state.card = Object.assign({}, res.card)
        }
        // @ts-ignore
        if(res.subscription && res.subscription.next_billing_at)
        {
            // @ts-ignore
            this.state.next_date = new Date(res.subscription.next_billing_at * 1000);
        }
        if(res.provider){
            this.state.provider = res.provider;
        }

        // @ts-ignore
        this.state.in_trial = ((res.subscription.status == 'in_trial') ? true : false)
        this.state.trial_ends_on_date = 0
        if(this.state.in_trial == true && res.subscription.trial_end) {
            let renewal_date =  res.subscription.trial_end
            let end_date = new Date(renewal_date * 1000)
            let today = new Date()
            let dif= Math.abs(end_date.getTime() - today.getTime());
            this.state.trial_ends_on_date = Math.floor(dif/(1000 * 3600 * 24))
        }

        // @ts-ignore
        this.state.subscription_status = res.subscription.status
        // @ts-ignore
        this.state.subscription_cost = res.subscription.subscription_amount / 100
        // @ts-ignore
        this.state.currency_code = res.subscription.currency_code
        
        // @ts-ignore
        if(res.subscription.next_billing_at) {
        // @ts-ignore
            let renewal_date =  res.subscription.next_billing_at
            let date = new Date(renewal_date * 1000)
            this.state.subscription_renewal_at = date.toDateString()
        }

        this.state.current_plan = res.plan

        return Promise.resolve(res);
    },

    async getUsage(profile_id){
        let usage = await TenantsCore.usage.getConsumptionSummary({profile: profile_id});
        if(usage) {
            this.state.usage.splice(0, this.state.usage.length);
            this.state.usage.push(...usage);
        }
        return usage;
    },

    async getResellerId() {
        const res = await TenantsCore.reseller.getResellers({user_id: userId});
        return res;
    },

    async getBillingPlans(marketplace) {
        this.state.monthly_plans = [];
        this.state.annual_plans = [];
        let res = null;

        if(user.state.userData.role === 'reseller') {
            this.state.resellerData = await this.getResellerId();
            res = await TenantsCore.reseller.getResellerBundles({reseller_id: this.state.resellerData?.id});
        } else {
            res = await TenantsCore.billing.getBillingPlans({marketplace});
        }

        if(res) {
            res = res.filter(el => el.currency_code === 'GBP' && el.period === 1);
            this.state.plans = res;

            this.state.monthly_plans = res.filter(el => el.period_unit == "month");
            this.state.annual_plans = res.filter(el => el.period_unit == "year");

            this.state.monthly_plans.forEach(el => {
                el.current_plan = el.id.includes(this.state.current_plan.id);

                el.price = el.tiers.find(el => el.starting_unit == 1).price/100
            })

            this.state.annual_plans.forEach(el => {
                el.current_plan = el.id.includes(this.state.current_plan.id);

                el.price = el.tiers.find(el => el.starting_unit == 1).price/100
            })
        } else {
            throw "No Plans";
        }

        return Promise.resolve(res);
    },

    async getSelectedPlan(plan_id, marketplace) {
        let res = await TenantsCore.billing.getBillingPlans({marketplace});

        if(res) { 
            this.state.selected_plan = res.find(el => el.id == plan_id)
        } else { 
            throw "No selected plan";
        }

        return Promise.resolve(res);
    },

    async getLimits(profile) {
        let res = await TenantsCore.billing.getBillingLimits({profile});
        if (res) {
            this.state.myBillingLimits = res;
        }
    },

    async resellerSubscribeToPlan(payload) {
        let res = await TenantsCore.reseller.postResellerProfileSubscriptionUpdate({
            reseller_id: this.state.resellerData?.id,
            profile: getCurrentProfileId(),
            payload: payload
        });
        return res;
    },

    async cancelSubscription() {
        this.state.resellerData = await this.getResellerId();

        await TenantsCore.reseller.postResellerProfileSubscriptionCancellation({
            reseller_id: this.state.resellerData?.id,
            profile: getCurrentProfileId()
        });
    }
}

