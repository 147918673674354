import Vue from 'vue';
import { channelsCore } from "@/services"

export interface Channels {
    //@ts-ignore
    channelsList: { [key: string]: any },
    channelsStructure: {},
    channelDataToCreatePlatform: {},
    whatsappApplication: {},
    channelsTypesTable: [],
    showChannelsTypesTable: false
}

export const Channels = {

    state: Vue.observable<Channels>({
        channelsList: {},
        channelsStructure: { //this should be here to allow locked and unlocked channels as it used in many components
            facebook: {
                src: 'img/channels-imgs/icons/messenger-logo.svg',
                name: 'Facebook',
                backgroundColor: '#1CA1E41A',
                allowed: true,
                locked: false
            },
            facebook_comment: {
                src: 'img/channels-imgs/icons/messenger-logo.svg',
                name: 'Facebook',
                backgroundColor: '#1CA1E41A',
                allowed: true,
                locked: false
            },
            webchat: {
                src: 'img/channels-imgs/icons/webchat-logo.svg',
                name: 'Webchat',
                backgroundColor: '#AD66CC1A',
                allowed: true,
                locked: false
            },
            whatsapp: {
                src: 'img/channels-imgs/icons/whatsapp-logo.svg',
                name: 'WhatsApp',
                backgroundColor: '#67C15E1A',
                allowed: true,
                locked: false
            },
            sms: {
                src: 'img/channels-imgs/icons/sms-icon.png',
                name: 'SMS',
                backgroundColor: '#10628B1A',
                allowed: false,
                locked: false
            },
            qr: {
                src: 'img/channels-imgs/icons/qr-icon.png',
                name: 'QR Code',
                backgroundColor: '#494D4F1A',
                allowed: false,
                locked: false
            },
            instagram: {
                src: 'img/channels-imgs/icons/instagram-icon.png',
                name: 'instagram',
                backgroundColor: '#494D4F1A',
                allowed: false,
                locked: false
            },
            gmail: {
                src: 'img/channels-imgs/icons/gmail-icon.png',
                name: 'Gmail',
                backgroundColor: '#494D4F1A',
                allowed: false,
                locked: false
            },
            call: {
                src: 'img/channels-imgs/icons/call-icon.png',
                name: 'Call',
                backgroundColor: '#494D4F1A',
                allowed: false,
                locked: false
            },
            record: {
                src: 'img/channels-imgs/icons/call-icon.png',
                name: 'Call',
                backgroundColor: '#494D4F1A',
                allowed: false,
                locked: false
            },
            email: {
                src: 'img/channels-imgs/icons/email-logo.svg',
                name: 'Email',
                backgroundColor: '#1CA1E41A',
                allowed: true,
                locked: false
            },
        },
        channelDataToCreatePlatform: {}, //if user cancel creation of platform and start to edit it will re route him to create page with channel data
        whatsappApplication: {},
        //@ts-ignore
        channelsTypesTable: [
            {type: 'WebChat', status: 'Active', configuration: 'Install', channel: 'webchat', platform: '', tag: '', id: ''},
            {type: 'WhatsApp', status: 'Inactive', configuration: 'Setup', channel: 'whatsapp', platform: '', tag: '', id: ''},
            {type: 'Messenger', status: 'Active', configuration: 'Setup', channel: 'facebook', platform: '', tag: '', id: ''},
            {type: 'Email', status: 'Active', configuration: 'Setup', channel: 'email', platform: '', tag: '', id: ''},
        ],
        showChannelsTypesTable: false
    }),

    clearChannelTypesTable() {
        this.state.channelsTypesTable = [];
    },

    // get profile channels
    async getProfileChannels(profileId: number) {
        this.state.channelsTypesTable = [];

        let channel_crash = '';
        
        try {
            let webchatChannels =  await this.getWebchatChannels(profileId);

            this.assignChannelData(webchatChannels, 'WebChat', 'webchat');
        } catch (error) {
            channel_crash = error as string;
        }

        try {
            let whatsappChannels = await this.getWhatsappSettings(profileId);

            this.assignChannelData(whatsappChannels, 'WhatsApp', 'whatsapp');
        } catch (error) {
            channel_crash = error as string;
        }

        try {
            let facebookChannels = await this.getFacebookChannels(profileId);

            this.assignChannelData(facebookChannels, 'Messenger', 'facebook');
        } catch (error) {
            channel_crash = error as string;
        }

        try {
            let emailChannels = await this.getEmailChannels(profileId);

            this.assignChannelData(emailChannels, 'Email', 'email');
        } catch (error) {
            channel_crash = error as string;
        }

        this.state.showChannelsTypesTable = true

        if(channel_crash == '') {
            return Promise.resolve(this.state.channelsTypesTable)
        } else {
            return Promise.reject(channel_crash);
        }
    },

    assignChannelData(channelArray, channelName, channelType) {
        let activeChannelsCount = channelArray.filter(el => el.enable == true).length

        if(channelArray.length>0 && activeChannelsCount >= 1) {
            this.state.channelsTypesTable.push({ 
                type: channelName, 
                status: activeChannelsCount>=1 ? 'Active': 'Inactive',
                configuration: activeChannelsCount>=1 ? 'Setup': 'Install',
                channel: channelType,
                platform: channelArray.filter(el => el.enable == true)[0].platform,
                tag: channelArray.filter(el => el.enable == true)[0].tag,
                id: channelArray.filter(el => el.enable == true)[0].id
            });
        } else {
            this.state.channelsTypesTable.push({ 
                type: channelName, 
                status: 'Inactive',
                configuration: 'Install',
                channel: channelType,
            });
        }
    },

    //* facebook channels
    getFacebookChannels(profileId){
        return new Promise(function(resolve,reject){
            const facebookChannels =channelsCore.metaChannel.facebookSettings.facebookSettingsGet({
                profile_id: profileId
            });
        (facebookChannels) ? resolve(facebookChannels) : reject(new Error('failed to fetch facebook channels data'));
        });
    },

    //* webchat channels
    getWebchatChannels(profileId){
        return new Promise(function(resolve,reject){
        let webchatChannels =  channelsCore.webChatChannels.webchatSettings.webchatSettingsGet({
            profile_id: profileId
        });
        if(webchatChannels){
            resolve(webchatChannels);
        }
        else {
            reject(new Error('failed to fetch webchat channels data'));
        }})
    },

    getWhatsappSettings(accountId?){
        return new Promise(function(resolve,reject){
        const res = channelsCore.metaChannel.whatsAppSettings.whatsAppSettingsGet({
            whatsapp_business_account_id: accountId
        });
        (res) ? resolve(res) : reject(new Error('failed to fetch whatsapp channels data'));
    });
    },

    //* email channels
    getEmailChannels(profileId){
        return new Promise(function(resolve,reject){
        const emailChannels =  channelsCore.emailChannels.emailSettings.emailSettingsGet({
            profile_id: profileId
        });
        (emailChannels) ? resolve(emailChannels) : reject(new Error('failed to fetch email channels data'));
    });
    },
}