
const emptyPaths = {
    // Blank layout
    path: '*',
    meta: {
      guard: 'PUBLIC',
    },
    name:'404',
    component: () => import(/* webpackChunkName: "emptyPaths" */'@/views/NotFound.vue')
}

export default emptyPaths