import { user } from './user'
import { profile } from './profile'
import { Channels  } from './channels'
import { Tenants } from './tenants'
import { BillingStore as billing } from './billing'

const HomeStore = {
    user,
    profile,
    Channels,
    Tenants,
    billing
}

export const hstore = {
    install(h: any) {
        h.mixin({
            beforeCreate: function() {
                this.$hstore = HomeStore;
            }
        })
    }
}

export default HomeStore