/* eslint-disable promise/no-callback-in-promise */
import Vue from 'vue'
import Router, { NavigationGuardNext, Route } from 'vue-router'
import Meta from 'vue-meta'
import { config } from '@/config'
import {setApiKey} from "@/services"
import store from '@/store';
import billing from '@/router/billing';
import emptyPaths from '@/router/emptyPaths';
import BlankLayout from '@/layout/BlankLayout.vue';

import { oauthGuard, isPagePublic } from '@tactfulEngage/utilities'

Vue.use(Router)
Vue.use(Meta)

let getProfilesCounter = 0;

async function onAuthSuccess(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (!isPagePublic(to)) {
    setApiKey();  // after logged in, set apiKey with new token 
    await loadProfile(next);
  }
  next()
}

async function loadProfile(next: NavigationGuardNext<Vue>) {
  if(getProfilesCounter == 0){//to prevent many calls for apis just for first and add to store
    try{
      await store.user.getUserInformation()
      await store.user.getUserProfiles()
      getProfilesCounter++;
    }catch(error){
      console.error(error);
      next({name: "404"})
    }
  }  
}

async function authGuard(to: Route, from: Route, next: NavigationGuardNext<Vue>){
  store.profile.setLoading(true);
  
  await oauthGuard(to, async (event)=>{
    if(event === 'SUCCESS'){
      await onAuthSuccess(to,from,next)
    } else if (event == 'UNAUTHORIZED') {
      next({name: "Unauthorized"});
    } else {
      next({name: "404"})
    }
});
}

window.addEventListener('TOKEN_REFRESHED', function() { 
  setApiKey(); 
}); 

export function initRouter() {
  const router = new Router({
    base: config.BASENAME,
    mode: 'history',
    routes: [
      {
        path: "/",
        redirect: "/admin-home"
      },
      {
        path: "/admin-home",
        name: "AdminHome",
        meta: {
          guard: 'NEED_PERMISSION',
        },
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
      },
  
      {
        path: '/preview',
        component: BlankLayout,
        children: [
          {
            path: '/',
            name:'previewChannel',
            meta:{
              guard: 'NEED_AUTH',
            },
            component: () => import(/* webpackChunkName: "previewChannel" */ '../views/PreviewChannel')
          }
        ]
      },
  
      {
        path: '/business-info',
        name: 'BussinessInfo',
        meta: {
          guard: 'NEED_PERMISSION',
          openInNoSubscription: 'ALLOWED',
        },
        component: () => import(/* webpackChunkName: "businessInfo" */ '@/views/BusinessInfo.vue'),
      },
  
      ...billing,
      emptyPaths
    ]
  });

  router.beforeEach(authGuard);
  router.afterEach(() => store.profile.setLoading(false));

  return router;
}
