import BillingLayout from '@/layout/BillingLayout.vue';
import HomeStore from '@/store';
import { getCurrentProfileId, getProfileRole } from '@tactfulEngage/utilities'


export default [    
    {
        path: '/subscription-management',
        component: BillingLayout,
        children: [
            {
                name: 'SubscriptionManagement',
                path: '',
                meta: {
                    guard: 'NEED_PERMISSION',
                    openInNoSubscription: 'ALLOWED',
                },
                component: () => import(/* webpackChunkName: "billing" */'@/views/SubscriptionManagment.vue'),
            },
            {
                name: 'plans',
                path: 'plans',
                meta: {
                    guard: 'NEED_PERMISSION',
                    openInNoSubscription: 'ALLOWED',
                },
                component: () => import(/* webpackChunkName: "billing" */'@/views/Plans.vue'),
            },
        ],
        beforeEnter: async (to, from, next) => {
            await HomeStore.Tenants.getProfile(getCurrentProfileId());
            let accountBelongToReseller = HomeStore.Tenants.state.profileData?.has_reseller && !(HomeStore.user.state.userData.role === 'reseller' || getProfileRole() === "account_manager");
            if(accountBelongToReseller){
                next({path: '/unauthorized'});
            } else {
                next();
            }
            return;
        },
    },
    {
        path: '/trial-ended',
        name: 'TrialEnded',
        meta: {
            guard: 'NEED_PERMISSION',
            openInNoSubscription: 'ALLOWED',
        },
        component: () => import(/* webpackChunkName: "billing" */ '@/views/TrialEnded.vue'),
    },
    {
        path: '/checkout',
        name: 'Checkout',
        meta: {
            guard: 'PUBLIC'
        },
        component: () => import(/* webpackChunkName: "checkout" */ '@/views/CheckoutPage.vue'),
    },
    {
        path: '/not-authorz',
        name: '403',
        meta: {
            guard: 'PUBLIC',
        },
        component: () => import('@/views/403.vue'),
    },
]