import { authCore } from '@/services';
import Vue from 'vue';
import { logoutUser } from '@tactfulEngage/utilities'
import { Profile } from '@tactful/tenant-sdk';
import { ProfileMembership } from '@tactful/auth';
export interface UserState {
    name: string;
    username: string;
    profileRoles: ProfileMembership[];
    profiles: Profile[];
    userData: {
        userId: number,
        name: string,
        email: string,
        picture_url: string,
        phone_number: string,
        organization: string,
        department: string,
        job_title: string,
        language: string,
        timezone: string,
        country: string,
        city: string,
        role: string,
        email_verified: boolean,
        subscribe_marketing: boolean,
        subscribe_product_updates: boolean,
        call_center_id:number,
        call_center_number:number,
        priviliged_to_delete_comment: boolean,
    },
    profileInvitations: [],
    profileUsers: []
}

export const user = {
    state: Vue.observable<UserState>({ //leave all empty until signup/login set them up
        name: "",
        username: "",
        profileRoles: [],
        profiles: [],
        userData: {
            userId: 0,
            name: "",
            email: "",
            picture_url: "",
            phone_number: "",
            organization: "",
            department: "",
            job_title: "",
            language: "",
            timezone: "",
            country: "",
            city: "",
            role: "",
            email_verified: null,
            subscribe_marketing: null,
            subscribe_product_updates: null,
            call_center_id:null,
            call_center_number:null,
            priviliged_to_delete_comment: null,
        },
        profileInvitations: [],
        profileUsers: []
    }),

    userProfileRole(profile_id) {
        if(this.state.profileRoles.length > 0){
            let profileRole = this.state.profileRoles.find(p => {
                return p.profile.id == profile_id
            })
            return profileRole && profileRole.role ? profileRole.role : null //may the user open a profile isn't related to him
        }else{
            return null;
        }
    },
    profile(profile_id) {
        if(this.state.profileRoles.length > 0){
            return this.state.profileRoles.find(p => p.profile.id == profile_id).profile
        }else{
            return null;
        }
    },
    get user() {
        return {
            userName: this.state.userData.name,
            userId: this.state.userData.userId,
            email: this.state.userData.email,
            call_center_id: this.state.userData.call_center_id,
            call_center_number: this.state.userData.call_center_number,
            priviliged_to_delete_comment: this.state.userData.priviliged_to_delete_comment,
            picture_url: this.state.userData.picture_url,
        }
    },
    async getUserInformation() {
        let user = await authCore.authentication.showCurrentUser({});
        this.state.userData.userId = user.id;
        this.state.userData.email = user.email;
        this.state.userData.name = user.name;
        this.state.userData.phone_number = user.phone_number;
        this.state.userData.country = user.country;
        this.state.userData.city = user.city;
        this.state.userData.role = user.role;
        this.state.userData.department = user.department;
        this.state.userData.picture_url = user.picture_url;
        this.state.userData.organization = user.organization;
        this.state.userData.job_title = user.job_title;
        this.state.userData.language = user.language;
        this.state.userData.timezone = user.timezone;
        this.state.userData.subscribe_marketing = user.subscribe_marketing;
        this.state.userData.subscribe_product_updates = user.subscribe_product_updates;
        this.state.userData.call_center_id = user.call_center_id;
        this.state.userData.call_center_number = user.call_center_number;
        this.state.userData.priviliged_to_delete_comment = user.priviliged_to_delete_comment;
        Vue.delete(this.state.userData, "email_verified");
        Vue.set(this.state.userData, "email_verified", user.email_verified);
    },
    async getUserProfiles() {
        let memberships = await authCore.memberships.getCurrentUserMemberships({});

        memberships.forEach(membership => {
            membership["profile"]={
                "id": membership.profile_id,
                "name": `profile_${membership.profile_id}`,
                "profile_name": `profile ${membership.profile_id}`
            }
        });
        
        this.saveProfiles(memberships);
    },
    
    async logout() {
        this.state.username = null;
        if (this.state.profileRoles){
            this.state.profileRoles.splice(0);
        }
        //new logout
        logoutUser();
        return true;
    },
    saveProfiles(profiles: ProfileMembership[]) {
        this.state.profileRoles.splice(0, this.state.profileRoles.length);
        this.state.profiles.splice(0, this.state.profiles.length);
        if (profiles) {
            this.state.profileRoles.push(...profiles);
            this.state.profiles.push(...profiles.map(p=> p.profile))
        }
    },

    async getProfileInvitations(profile_id) {
        this.state.profileInvitations = await authCore.invitations.getProfileInvitations({profile: profile_id})
        return Promise.resolve(this.state.profileInvitations)
    },

    async getProfileUsers() {
        let profileUsers = await authCore.memberships.getProfileUsers({});
        this.state.profileUsers = profileUsers;
        return Promise.resolve(this.state.profileUsers);
    }
};
