import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './en'
import arLocale from './ar'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale
  },
  ar: {
    ...arLocale
  }
}


const dateTimeFormats = {
  'en': {
    short: {
      month: 'short', day: 'numeric',
    },
    long: {
      month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric'
    }
  },
  'ar': {
    short: {
      month: 'short', day: 'numeric'
    },
    long: {
      month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric'
    }
  }
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: Cookies.get('language') || 'en',
  silentTranslationWarn: true,
  // set locale messages
  messages,
  dateTimeFormats,
  missing(lang, key) {
    if (!key) {
      return
    }
    const newKey = (key.replace(/^\w+\./, ""));
    return newKey.charAt(0).toUpperCase() + newKey.slice(1);
  }
})

export default i18n
