export default {
    "billing":{
       "account":"Billing Account",
       "plan":"Plan",
       "status":"Status",
       "cost":"Unit Cost",
       "qunatity":"Qunatity",
       "managePlan":"Manage Plan",
       "usage":"USAGE",
       "startdate":"Start date",
       "enddate":"End date",
       "usageDetails":"Usage details",
       "agentSeats":"Total Agents Seats",
       "monthlycustomers":"Monthly Customers",
       "monthlycampaigns":"Monthly Campaigns",
       "utilized":"Utilized",
       "unlimited":"Unlimited",
       "customers":"Customers",
       "seats":"Seats",
       "campaigns":"Campaigns",
       "billingHistory":"Billing History",
       "transaction":"Transaction ID",
       "childRoute":"All Plans",
       "in_trial":"Trial",
       "active":"Active",
       "cancelled":"Cancelled",
       "no_billing_state":"Status Unavailable"
    },
    "billing_period_unit":{
       "month":"monthly",
       "year":"annually"
    },
    "homepage_popup":{
       "title":"!تهاني... انت جاهز للبدء",
       "btn_1":"تحربة كعميل",
       "btn_2":"تجربة كوكيل",
       "btn_3":"دعوة الفريق"
    },
    "meta":{
       "homepage":"الصفحة الرئيسية"
    },
    "progress_bar":{
       "business_info":"Business Info",
       "setup_languages":"Setup Languages",
       "invite_team":"Invite Team",
       "personal_profile":"Personal Profile"
    }
 }