import Vue from 'vue'
import singleSpaVue from 'single-spa-vue';

import App from './App.vue'
import { config } from './config';
import "./vendor/styles/pages/tactful-table.scss";

import { bugsnagClient } from '@tactfulEngage/utilities';
import bugsnagVue from '@bugsnag/plugin-vue';
import i18n from './lang';
import { initRouter } from './router';


import { 
  ModalPlugin,
  BContainer,
  BCard,
  BTable,
  TooltipPlugin,
  BOverlay,
  BNavItem,
  BModal,
  BRow,
  BCol
} from 'bootstrap-vue'

import { hstore } from './store';

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  async appOptions() {
    return {
      render(h) {
        return h(App, {
          props: {
            // single-spa props are available on the "this" object. Forward them to your component as needed.
            // https://single-spa.js.org/docs/building-applications#lifecycle-props
            // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
            /*
            name: this.name,
            mountParcel: this.mountParcel,
            */
           singleSpa: this.singleSpa,
          },
        });
      },
      router: initRouter(),
      i18n
    }
  },
  handleInstance: (app, props) => {
    Vue.prototype.$singleSpa = props.singleSpa;
  }
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;



Vue.use(hstore);

Vue.component('b-container', BContainer)
Vue.component('b-card', BCard)
Vue.component('b-table', BTable)
Vue.component('b-overlay', BOverlay)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-modal', BModal);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin);


if (config.STAGE !== 'development') {
  // bugsnag plugin-vue
  bugsnagClient.use(bugsnagVue, Vue);

  // notify unhandled rejection errors like promises 
  window.onunhandledrejection = function (event) {
    console.error(event);
    bugsnagClient.notify(event.reason);

  };
}