
// this code will load the config from process.env which is filled by webpack at build time
// this forces us to build a sepecific version for every single environment which sucks
// the other option is to deploy using SSR or to use this mechanism below this code
let env = {
    STAGE: process.env.STAGE,
    DASHBOARD_HOST_URL: process.env.DASHBOARD_HOST_URL,
    CHANNELS_API_HOST_URL: process.env.CHANNELS_API_HOST_URL,
    CHANNELS_WEBCHAT_API_HOST_URL: process.env.CHANNELS_WEBCHAT_API_HOST_URL,
    CHARGEBEE_SITE: process.env.CHARGEBEE_SITE,
    CHARGEBEE_PUBLISHABLE_API_KEY: process.env.CHARGEBEE_PUBLISHABLE_API_KEY,
    EMAIL_API_HOST_URL: process.env.EMAIL_API_HOST_URL,
    AUTH_API_HOST_URL: process.env.AUTH_API_HOST_URL,
    BASENAME: process.env.BASENAME,
    TENANT_API_HOST_URL: process.env.TENANT_API_HOST_URL,
    EVENTS_HOST_URL: process.env.EVENTS_HOST_URL,
    business_info: process.env.business_info,
    setup_languages: process.env.setup_languages,
    invite_team: process.env.invite_team,
    personal_profile: process.env.personal_profile,
    subscription_route: process.env.subscription_route,
    support_center:process.env.support_center,
    FAQ:process.env.FAQ,
    Queues_setup:process.env.Queues_setup,
    UTM_PARTNER:process.env.UTM_PARTNER,
    WEBCHAT_WIDGET_PROFILE_ID: process.env.WEBCHAT_WIDGET_PROFILE_ID,
    WEBCHAT_WIDGET_ID: process.env.WEBCHAT_WIDGET_ID,
    ATTACHMENTS_API_HOST_URL: process.env.ATTACHMENTS_API_HOST_URL,
    ERP_API_HOST_URL: process.env.ERP_API_HOST_URL,
    GATEWAY_ACCOUNT_ID: process.env.GATEWAY_ACCOUNT_ID,
    TRIAL_ACTIVATION_PLAN: process.env.TRIAL_ACTIVATION_PLAN
};

// overwrite the config from the index.html page which loads environment variables
// this makes the vdash friendly to docker environmemnts.
// if this is not a docker container, then we should skip this
if (window.env) {
    for (const k in env) {
        if (window.env[k]) {
            env[k] = window.env[k];
        }
    }
}

export const config = env;